import { render, staticRenderFns } from "./BottomBanners.vue?vue&type=template&id=3cd8e562&scoped=true&"
import script from "./BottomBanners.vue?vue&type=script&lang=js&"
export * from "./BottomBanners.vue?vue&type=script&lang=js&"
import style0 from "./BottomBanners.vue?vue&type=style&index=0&id=3cd8e562&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cd8e562",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmartImage: require('C:/Workspace/Edujobic/Gitlab Frontend/components/global/SmartImage.vue').default})
