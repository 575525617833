//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
    Pass location (page name) to get the banner adverts in top position (2 slots)
*/
import * as ptypes from '~/utilities/props-types';
export default {
    data: function () {
        return {
            ads: [],
            key: 2000,
        };
    },
    props: {
        location: ptypes.vSTRING('home'),
    },
    async mounted() {
        this.ads = await this.$getBanners(this.location, 0);
    },
    watch: {
        async '$route.query.p'() {
            this.ads = await this.$getBanners(this.location, 0);
            this.key++;
        },
    },
};
