export function getInstitutionCategoryId_Session() {
    return localStorage.getItem('institutionCategoryId') ? localStorage.getItem('institutionCategoryId') : 1;
}

export function getInstitutionCategoryUrl_Session() {
    return localStorage.getItem('institutionCategoryId') == 1 ? '/higher' :
        localStorage.getItem('institutionCategoryId') == 2 ? '/further' : '/school';
}

export function getInstitutionCategoryId_UI() {
    var eb = localStorage.getItem('extrabase');
    if (!eb) {
        eb = 'higher';
    }
    var t =
        $nuxt.$route.path == '/' ?
        'higher' :
        $nuxt.$route.path.startsWith('/further') ?
        'further' :
        $nuxt.$route.path.startsWith('/school') ?
        'school' :
        $nuxt.$route.path.startsWith('/higher') ?
        'higher' :
        eb ?
        eb :
        'higher';
    return t == 'higher' ? 1 : t == 'further' ? 2 : t == 'school' ? 3 : 1;
}

export function getInstitutionCategoryUrl_UI() {
    var eb = localStorage.getItem('extrabase');
    return eb ? "/" + eb : "";
}

export function extrabaseLogic() {
    var eb = localStorage.getItem('extrabase');
    if (!eb) {
        eb = 'higher';
    }
    var t =
        $nuxt.$route.path == '/' ?
        'higher' :
        $nuxt.$route.path.startsWith('/further') ?
        'further' :
        $nuxt.$route.path.startsWith('/school') ?
        'school' :
        $nuxt.$route.path.startsWith('/higher') ?
        'higher' :
        eb ?
        eb :
        'higher';
    if ($nuxt.$route.query.ic == 1) {
        t = 'higher';
        localStorage.setItem('extrabase', t);
    }
    if ($nuxt.$route.query.ic == 2) {
        t = 'further';
        localStorage.setItem('extrabase', t);
    }
    if ($nuxt.$route.query.ic == 3) {
        t = 'school';
        localStorage.setItem('extrabase', t);
    }

    localStorage.setItem('extrabase', t);
}

export function mob() {
    const w = window.innerWidth;
    return w <= 580;
}
export function lg() {
    const w = window.innerWidth;
    return w >= 992;
}
export function isEmployer() {
    return localStorage.getItem('employer');
}
export function isSeeker() {
    return localStorage.getItem('seeker');
}

export function ckconfig() {
    return {
        removePlugins: 'elementspath',
        extraPlugins: 'copyformatting,showblocks,colorbutton,bidi,print,selectall,a11yhelp,basicstyles,blockquote,clipboard,listblock,format,horizontalrule,htmlwriter,wysiwygarea,indent,indentlist,list,magicline,maximize,pastetext,pastetools,pastefromgdocs,pastefromlibreoffice,pastefromword,removeformat,showborders,sourcearea,specialchar,menubutton,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,exportpdf,autogrow,textwatcher,textmatch,tableresize,justify,font,find,autolink,liststyle,indentblock,preview',
        toolbar: [{
                name: 'document',
                items: [
                    'ExportPdf',
                ]
            },
            {
                name: 'clipboard',
                items: [
                    'Cut',
                    'Copy',
                    '-',
                    'Undo',
                    'Redo'
                ]
            },
            {
                name: 'editing',
                items: [
                    'Find',
                    'Replace',
                    '-',
                    'SelectAll',
                    '-',
                    'Scayt'
                ]
            },
            '/',
            {
                name: 'basicstyles',
                items: [
                    'Bold',
                    'Italic',
                    'Underline',
                    'Strike',
                    'Subscript',
                    'Superscript',
                ]
            },
            {
                name: 'paragraph',
                items: [
                    'NumberedList',
                    'BulletedList',
                    '-',
                    'Outdent',
                    'Indent',
                    '-',
                    'JustifyLeft',
                    'JustifyCenter',
                    'JustifyRight',
                    'JustifyBlock',
                    '-',
                    'BidiLtr',
                    'BidiRtl',
                ]
            },
            '/',
            { name: 'insert', items: ['HorizontalRule'] },

            {
                name: 'styles',
                items: ['FontSize']
            },
            { name: 'colors', items: ['TextColor', 'BGColor'] },
        ],
        removeButtons: '',
        versionCheck: false,
        on: {
            change: function(content) {},
            key: function(obj) {
                if (obj.data.keyCode === 8 || obj.data.keyCode === 46) {
                    return true;
                }
                if (obj.editor.document.getBody().getText().length >= 4000) {
                    //alert('No more characters possible');
                    return false;
                } else { return true; }

            }
        }
    }
};

export function youtube_parser(url) {
    var video_id = url.split('v=')[1];
    if (!video_id) {
        if (url.includes("youtu.be")) {
            url = url.split('?')[0];
            url = url.split('/');
            video_id = url[url.length - 1];
            return video_id;
        }
        return;
    }
    var ampersandPosition = video_id.indexOf('&');
    if (ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
    }
    return video_id;
}

export function select2open(e) {
    document.getElementsByClassName(
        'select2-search__field'
    )[0].placeholder = 'Search..';
    document.getElementsByClassName(
        'select2-search__field'
    )[0].focus();
}

export function isJsonString(str) {
    if (!str) return false
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function errorModal(messages = []) {
    this.$modal.show(
        () =>
        import ('~/components/features/modal/MessagesModal'), {
            messages: messages,
            type: 3
        }
    );
}
export function warningModal(messages = []) {
    this.$modal.show(
        () =>
        import ('~/components/features/modal/MessagesModal'), {
            messages: messages,
            type: 2
        }
    );
}
export function infoModal(messages = []) {
    this.$modal.show(
        () =>
        import ('~/components/features/modal/MessagesModal'), {
            messages: messages,
            type: 1
        }
    );
}

export function showInfoPopup(info) {
    this.$modal.show(
        () =>
        import ('~/components/features/modal/MessagesModal'), {
            messages: [info],
            type: 10
        }
    );
}

export function storeLocalLoginInfo(email, data, userType) {
    localStorage.setItem('email', email);
    if (userType == 1) { //Employer
        localStorage.setItem('employer', true);
        localStorage.setItem('id', data.Id);
        localStorage.setItem('fullName', data.FullName);
        localStorage.setItem('url', data.Url);
        localStorage.setItem('imagePath', data.ImagePath);
        localStorage.setItem('emailConfirmed', data.EmailConfirmed);
        localStorage.setItem('accountApproved', data.AccountApproved);
        localStorage.setItem(
            'features',
            JSON.stringify(data.Features)
        );
        localStorage.setItem(
            'institutionCategory',
            JSON.stringify(data.InstitutionCategory)
        );
        localStorage.setItem(
            'institutionCategoryId',
            data.InstitutionCategory.Value
        );
        localStorage.setItem(
            'institutionType',
            JSON.stringify(data.InstitutionType)
        );
    } else if (userType == 2) { // Job Seeker
        localStorage.setItem('seeker', true);
        localStorage.setItem('id', data.Id);
        localStorage.setItem('fullName', data.FullName);
        localStorage.setItem('imagePath', data.ImagePath);
        localStorage.setItem('forename', data.Forename);
        localStorage.setItem('surname', data.Surname);
        localStorage.setItem('mobileNumber', data.MobileNumber);
        localStorage.setItem('title', JSON.stringify(data.Title));
        localStorage.setItem('country', JSON.stringify(data.Country));
        localStorage.setItem('city', data.City);
        localStorage.setItem('emailConfirmed', data.EmailConfirmed);
    }

}

export function currencyCode(currency) {
    if (currency == 'GBP')
        return '£';
    else if (currency == 'EUR')
        return '€';
    else if (currency == 'USD')
        return '$';
    else
        return currency
}

export function prepareCounters(data) {
    var appsCounters = [];
    appsCounters[0] = [
        'Advert Views',
        parseInt(data.TextAdvertViews),
        'clr-1'
    ];
    appsCounters[1] = [
        'Total Submissions',
        parseInt(data.TotalSubmissions),
        'clr-1'
    ];
    appsCounters[2] = [
        'Unprocessed',
        parseInt(data.Submitted),
        'text-warning',
        1
    ];
    appsCounters[3] = ['Screening', parseInt(data.Screening), 'clr-1', 4];
    appsCounters[4] = [
        'Shortlisted',
        parseInt(data.ShortListed),
        'clr-2',
        5
    ];
    appsCounters[5] = [
        'Interviewing',
        parseInt(data.Interviewing),
        'clr-3',
        6
    ];
    appsCounters[6] = [
        'Candidate of High Consideration',
        parseInt(data.CandidateOfHighConsideration),
        'clr-4',
        9
    ];
    appsCounters[7] = [
        'Further Assessment',
        parseInt(data.FurtherAssessment),
        'clr-5',
        10
    ];
    appsCounters[8] = [
        'Reference and Background Check',
        parseInt(data.ReferenceAndBackgroundCheck),
        'clr-6',
        11
    ];
    appsCounters[9] = ['Offering', parseInt(data.Offering), 'clr-7', 8];
    appsCounters[10] = [
        'Successful',
        parseInt(data.Successful),
        'text-success',
        7
    ];
    appsCounters[11] = [
        'Withdrawn',
        parseInt(data.Withdrawn),
        'text-danger',
        2
    ];
    appsCounters[12] = [
        'Unsuccessful',
        parseInt(data.Unsuccessful),
        'clr-8',
        3
    ];
    return appsCounters;
}
export function inputLen(event, max) {
    const arr = event.target.value.split(/\s+/);
    const length = arr.length;
    if (length > max) {
        //const myString = event.target.value.substr(0, event.target.value.length - 1);
        var myString = '';
        for (var i = 0; i < max && i < arr.length; i++) {
            myString += arr[i] + ' ';
        }
        console.log('inputLen', length, max, myString)
        event.target.value = myString;
        return myString;
    } else {
        return event.target.value
    }
}