//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
    Pass location (page name) to get the banner adverts in bottom position
*/
import PvCarousel from '~/components/features/PvCarousel';
import * as ptypes from '~/utilities/props-types';
export default {
    components: {
        PvCarousel,
    },
    props: {
        location: ptypes.vSTRING('home'),
    },
    data: function () {
        return {
            ads: [],
            key: 5000
        };
    },
    async mounted() {
        this.ads = await this.$getBanners(this.location, 3);
    },
    watch: {
        async '$route.query.p'() {
            this.ads = await this.$getBanners(this.location, 3);
            this.key++;
        },
    },
};
