//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainMenu from '~/components/common/layout/MainMenu';
document.querySelector('body').classList.add('loaded');
import * as ptypes from '~/utilities/props-types';

export default {
    components: {
        MainMenu,
    },
    data: function () {
        return {
            isHome: ptypes.tBOOLEAN,
            isFullwidth: ptypes.fBOOLEAN,
            uri: window.location.pathname,

            fullName: localStorage.getItem('fullName'),
            forename: localStorage.getItem('forename'),
            chatCount: 0,
            wishlistCount: 0,
            jobAlertCount: 0,
            notificationCount: 0,
            extrabase: localStorage.getItem('extrabase')
                ? localStorage.getItem('extrabase')
                : 'higher',
            mounted: false,
            k: 0,
        };
    },
    computed: {
        emp() {
            return window.location.href.indexOf('/employer-porta') > -1;
        },
    },
    watch: {
        $route: async function () {
            this.uri = window.location.pathname;
            if (this.$route.path.includes('fullwidth')) {
                this.isFullwidth = true;
            } else this.isFullwidth = false;
            this.$extrabaseLogic();
            this.extrabase = localStorage.getItem('extrabase');
            if (this.$isSeeker) {
                await this.$checkSessionMiddleware(this.uri);
                this.k++;
            }
        },
    },
    mounted: async function () {
        this.mounted = true;
        if (this.$route.path.includes('fullwidth')) {
            this.isFullwidth = true;
        } else this.isFullwidth = false;
        if (localStorage.getItem('email')) {
            window.setInterval(() => {
                if (this.$isSeeker()) {
                    this.getChatCount();
                    this.getWishlistCount();
                    this.getJobAlertCount();
                    this.getNotificationsCount();
                }
            }, 30000);
        }
        this.$extrabaseLogic();
        this.extrabase = localStorage.getItem('extrabase');

        await this.$checkSession();
        this.k++;
    },
    methods: {
        showMobileMenu: function () {
            document.querySelector('body').classList.add('mmenu-active');
        },
        async getChatCount() {
            const R = await this.$GetReq(
                'in-app-conversation/new-messages-count'
            );
            if (R && R.success) {
                this.chatCount = R.data;
            }
        },
        async getWishlistCount() {
            const R = await this.$GetReq('job-seeker/wishlist/count');
            if (R && R.success) {
                this.wishlistCount = R.data;
            }
        },
        async getJobAlertCount() {
            const R = await this.$GetReq('job-seeker/job-alert/count');
            if (R && R.success) {
                this.jobAlertCount = R.data;
            }
        },
        async getNotificationsCount() {
            const R = await this.$GetReq(
                'job-seeker/notification-log/new-notifications-count'
            );
            if (R) {
                if (R.success) {
                    this.notificationCount = R.data;
                }
            }
        },
    },
};
