import axios from 'axios';

export const baseUrl = process.env.API_URL;
const customHeader = {
    'X-Requested-With': 'XMLHttpRequest',
    'withCredentials': true,
    'Access-Control-Allow-Origin': "*",
    "X-XSRF-TOKEN": localStorage.getItem("x-xsrf-token"),

    "content-security-policy": "default-src 'none'; script-src 'self' 'unsafe-inline' https://www.gstatic.com https://cdn.datatables.net https://maps.googleapis.com https://www.google.com; style-src 'self' 'unsafe-inline' https://fonts.googleapis.com https://cdn.datatables.net; img-src 'self' data: https://dev.file.edyougo.com https://files.edyougo.com https://maps.googleapis.com https://maps.gstatic.com https://lh3.ggpht.com; font-src 'self' https://fonts.gstatic.com; connect-src 'self' https://maps.googleapis.com; frame-src 'self' https://www.google.com; base-uri 'self'; block-all-mixed-content; media-src 'none'; object-src 'none'; worker-src 'none'; manifest-src 'none'; form-action 'none'; frame-ancestors 'none'; report-uri https://api.edyougo.com/csp-violation-report;",
    "permissions-policy": "accelerometer=(), autoplay=(), camera=(), cross-origin-isolated=(), display-capture=(), encrypted-media=(), fullscreen=(), geolocation=(), gyroscope=(), keyboard-map=(), magnetometer=(), microphone=(), midi=(), payment=(), picture-in-picture=(), publickey-credentials-get=(), screen-wake-lock=(), usb=(), web-share=(), xr-spatial-tracking=()",
    "referrer-policy": "strict-origin-when-cross-origin",
    "strict-transport-security": "max-age=31536000",
    "x-frame-options": "DENY",
};

let instance = axios.create({
    baseUrl,
    headers: customHeader,
    withCredentials: true,
});

instance.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        if (error.response) {
            if (error.response.status == 401) {
                localStorage.clear();
                if (window.location.pathname.includes("/employer-portal/")) {
                    localStorage.setItem('employer_redirect', window.location.origin + window.location.pathname + window.location.search)
                    window.location.replace("/auth/employer");
                } else {
                    window.location.replace("/auth/job-seeker");
                    localStorage.setItem('seeker_redirect', window.location.origin + window.location.pathname + window.location.search)
                }
            } else if (error.response.status == 403) {
                if (window.location.pathname.includes("/employer-portal/")) {
                    window.location.replace("/employer-portal");
                } else {
                    window.location.replace("/");
                }
            }
            return error.response;
        } else return error
    }
);

export function parseApiResponse(response) {
    if (response.headers && response.headers["x-xsrf-token"]) {
        localStorage.setItem("x-xsrf-token", response.headers["x-xsrf-token"])
    }

    if (response.status == 400) {
        var messages = response.data.Messages;
        if (messages)
            var errors = response.data.errors;
        if (errors) {
            for (const k in errors) {
                Vue.$toast.open({
                    message: "Technical Issue: " + errors[k],
                    type: 'error',
                });
            }
        }

        const data1 = response.data.Data;
        const messages1 = response.data.Messages;
        const success1 = false; // response.data.Success;
        return {
            success: false,
            code: response.status,
            messages: messages1
        }
    } else if (response.status == 401) {
        localStorage.clear();
        if (window.location.pathname.includes("/employer-portal/")) {
            localStorage.setItem('employer_redirect', window.location.origin + window.location.pathname + window.location.search)
            window.location.replace("/auth/employer");
        } else {
            window.location.replace("/auth/job-seeker");
            localStorage.setItem('seeker_redirect', window.location.origin + window.location.pathname + window.location.search)
        }
        return {
            success: false,
            code: response.status,
            messages: [],
        }
    } else if (response.status == 500) {
        Vue.$toast.open({
            message: "Technical Issue: 500",
            type: 'error',
        });
        const data1 = response.data.Data;
        const messages1 = response.data.Messages;
        const success1 = false; // response.data.Success;
        return {
            success: false,
            code: response.status,
            messages: [],
        }
    } else {
        if (response.data) {
            const data = response.data.Data;
            const messages = response.data.Messages;
            const success = response.data.Success;
            return {
                data: data,
                messages: messages,
                success: success,
                code: response.status
            }
        } else return null
    }
}

export async function GetReq(reqUrl, reqData = {}, extraHeader = {}, toSelect2 = false) {
    var response = await HTTP.get(baseUrl + reqUrl, { params: reqData }, extraHeader);
    const R = parseApiResponse(response);

    if (toSelect2 && R && R.data && R.data.DataSource) {
        var r = [];
        for (let i = 0; i < R.data.DataSource.length; i++) {
            r.push({
                id: R.data.DataSource[i].Value,
                text: R.data.DataSource[i].Text,
                more: R.data.DataSource[i].MoreData ? R.data.DataSource[i].MoreData.IsOther : false,
            });
        }
        return r;
    } else {
        return R;
    }
}

export async function PostReq(reqUrl, reqData, extraHeader = {}) {
    var response = await HTTP.post(baseUrl + reqUrl, reqData, extraHeader);
    const R = parseApiResponse(response);
    return R;
}

export async function PutReq(reqUrl, reqData, extraHeader = {}) {
    var response = await HTTP.put(baseUrl + reqUrl, reqData, extraHeader);
    const R = parseApiResponse(response);
    return R;
}

export async function DeleteReq(reqUrl, reqData, extraHeader = {}) {
    var response = await HTTP.delete(baseUrl + reqUrl, reqData, extraHeader);
    const R = parseApiResponse(response);
    return R;
}
export function remoteIdentifierObj(identifier, scope = "", noResults = "No Results Found") {
    return remoteIdentifier(identifier, scope, null, false, {}, true, noResults)
}
export function remoteIdentifier(identifier, scope = "", ParentIds = null, multiple = false, filter = {}, objVal = false, noResults = "No Results Found") {
    var fstr = "";
    if (Object.keys(filter).length > 0) {
        var i = 0;
        for (const property in filter) {
            fstr += 'Filters[' + i + '].Field=' + property + '&Filters[' + i + '].Value=' + filter[property] + "&";
            i++;
        }
    }
    return {
        allowClear: true,
        ajax: {
            url: baseUrl.slice(0, -1) + scope + '/data-sources' + '/get-reference-data-source',
            xhrFields: {
                withCredentials: true
            },
            delay: 1000,
            dataType: 'json',
            data: function(params) {
                var data = {
                    Identifier: identifier,
                    Query: params.term,
                    Page: params.page || 1,
                }
                if (ParentIds) {
                    data['ParentIds[0]'] = ParentIds
                }
                if (Object.keys(filter).length > 0) {
                    var i = 0;
                    for (const property in filter) {
                        data['Filters[' + i + '].Field'] = property;
                        data['Filters[' + i + '].Value'] = filter[property];
                        i++;
                    }
                }
                return data
            },
            processResults: function(data, params) {
                params.Page = params.page || 1;
                var r = [];
                for (let i = 0; i < data.Data.DataSource.length; i++) {
                    r.push({
                        id: !objVal ? data.Data.DataSource[i].Value : JSON.stringify({ id: data.Data.DataSource[i].Value, text: data.Data.DataSource[i].Text, moreData: data.Data.DataSource[i].MoreData }),
                        text: data.Data.DataSource[i].Text,
                        moreData: data.Data.DataSource[i].MoreData,
                        disabled: data.Data.DataSource[i].MoreData && data.Data.DataSource[i].MoreData.IsDowngraded
                    });
                }
                return {
                    results: r,
                    pagination: {
                        more: params.Page * data.Data.PageSize <
                            data.Data.TotalRecords,
                    },
                };
            },
            cache: true,
        },
        multiple: multiple,
        data: [{
            id: 1,
            text: "test"
        }],
        "language": {
            "noResults": function() {
                return noResults;
            }
        },
        escapeMarkup: function(markup) {
            return markup;
        }
    }
}

export async function logout(seeker, redirect = null) {
    this.$PostReq((seeker ? 'job-seeker' : 'employer') + '/account' + '/logout', {}).then(() => {
        localStorage.clear();
        location.href = redirect ? redirect : (seeker ? '/' : '/employer-portal/');
    });
}

export async function bannerClick(adId) {
    if (!adId) {
        return;
    }
    const R = await PostReq('banner-advert/click', {
        Id: adId
    });

    if (R && R.data && R.success) {
        window.open(R.data.replace('.com//', '.com/'), '_blank');
    }
}

export async function getBanners(location, target) {
    var ads = [];
    const R = await GetReq('banner-advert/' + location, {
        Target: target,
        InstitutionCategory: this.$getInstitutionCategoryId_UI()
    });
    if (R && R.success && R.data)
        for (var i = 0; i < R.data.length; i++) {
            if (R.data[i].Target == target) {
                ads.push(R.data[i]);
            }
        }
    return ads;
}
export async function checkSession() {
    if (localStorage.getItem('seeker') || localStorage.getItem('employer')) {
        const R = await GetReq('account/session-status');
        if (R && R.data && R.data.SysUserType.Value == 0) {
            localStorage.clear();
        }
    } else {
        localStorage.clear();
    }
}

export async function checkSessionMiddleware(uri) {
    if (localStorage.getItem('employer')) {
        const uris = [
            '/employer-portal/post-job-advert',
            '/employer-portal/pricing',
            '/employer-portal'
        ];
        if (
            uris.includes(uri) ||
            this.uri.startsWith('/employer-portal/blog')
        ) {
            await checkSession();
        }
    } else if (localStorage.getItem('seeker')) {
        if (
            this.uri.startsWith('/higher') || this.uri.startsWith('/further') || this.uri.startsWith('/school')
        ) {
            await checkSession();
        }
    }
}


export function sucmsg(context, messages = ["Done Successfully"], title = null) {
    context.$modal.show(() =>
        import ('~/components/features/modal/MessagesModal'), {
            messages: messages,
            title: title,
        });
}

export const HTTP = instance;